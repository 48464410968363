import React from 'react'
import { styled } from '@mui/material'
import { Dashboard } from '@mui/icons-material'

import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'

const StyledLink = styled(InternalButtonLink)(({ theme }) => ({
  textTransform: 'uppercase',
  letterSpacing: '0.1rem',
  fontSize: '1.2rem',
  fontWeight: '700',
  svg: {
    fontSize: '1.8rem',
  },
}))

const DashboardLink = () => {
  return (
    <StyledLink
      sizeVariant="xl"
      variant="outlined"
      colorVariant="outline-dark"
      slug="account/dashboard"
      startIcon={<Dashboard />}
    >
      Account Dashboard
    </StyledLink>
  )
}

export default DashboardLink
